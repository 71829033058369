import { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { useMobile } from 'src/hooks/useMobile'
import axios from 'axios'
import useStorage from 'src/sdk/hooks/useStorage'

import { useAccountContext } from '../../account/context'

type CashbackData = {
  data: {
    balance: number
  }
}

const CashbackBanner = () => {
  const { isLoggedIn } = useAccountContext()
  const { storageGetItem } = useStorage()
  const storageUserDecathlon = storageGetItem('user-decathlon', 'session')

  const [progress, setProgress] = useState<number>()
  const [progressBRL, setProgressBRL] = useState<string>()
  const [userCpf, setUserCpf] = useState<string>()

  useEffect(() => {
    if (storageUserDecathlon && userCpf === undefined) {
      setUserCpf(
        JSON.parse(storageUserDecathlon).getUserProfile.additional_information
      )
    }

    const convertValueBRL = (value: number): string => {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    }

    const getCashbackBalance = async () => {
      const { data }: CashbackData = await axios.post(
        '/api/getCashbackBalance',
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        }
      )

      if (!data) {
        return {
          message: 'Não foi possivel recuperar o valor de cashback.',
        }
      }

      setProgress(data.balance)
      setProgressBRL(convertValueBRL(data.balance))

      return data
    }

    if (isLoggedIn) {
      getCashbackBalance()
    }
  }, [isLoggedIn, progress, storageGetItem, storageUserDecathlon, userCpf])

  const { screenWidth } = useMobile()

  const displayCorrectImage = (width: number): string => {
    if (width < 767) {
      return 'https://decathlonstore.vteximg.com.br/arquivos/main-banner-cashback-mobile.png'
    }

    if (width >= 768 && width < 1023) {
      return 'https://decathlonstore.vteximg.com.br/arquivos/main-banner-cashback-tablet.png'
    }

    return 'https://decathlonstore.vteximg.com.br/arquivos/main-banner-cashback-desktop.png'
  }

  return (
    <section className="cashback-banner relative ppp:px-2 pp:px-4 sm:px-6 md:px-12 ppp:pb-5 md:pb-8 bg-white">
      <div className="cashback-banner__image relative">
        <img
          src={displayCorrectImage(screenWidth)}
          alt="Imagem do banner da página de Cashback"
          className="w-full"
        />
        <div className="cashback-banner__image__text absolute ppp:bottom-5 left-0 sm:max-w-min sm:left-1/2 -translate-x-1/2 md:left-[21rem] md:bottom-10">
          <h1 className="cashback-banner__image__text--title text-white uppercase my-0 font-bold italic ppp:text-3xl pp:text-6xl text-center ppp:mb-3 md:text-left md:text-8xl">
            cashback
          </h1>
          <p className="cashback-banner__image__text--content text-white font-bold ppp:text-[14px] pp:text-sm ppp:leading-3 text-center ppp:mb-7 pp:px-6 sm:px-2 md:text-xl md:text-left md:w-[570px]">
            O Cashback é um novo benefício do Clube Decathlon que te dá dinheiro
            de volta para você utilizar em novas compras!
          </p>
          <Link
            to="https://www.decathlon.com.br/clube/"
            className="cashback-banner__image__text--button font-bold text-center rounded-[4px] bg-[#FFEA28] w-fit ppp:text-xs pp:text-base ppp:px-5 pp:px-6 ppp:py-3 block mx-auto md:text-base"
          >
            Confira os benefícios do Clube
          </Link>
        </div>
      </div>
      <div className="cashback-banner__saldo bg-white flex flex-col rounded-lg ppp:relative ppp:mx-auto ppp:max-w-[351px] ppp:p-3 ppp:gap-3 ppp:mt-9 md:w-[550px] md:max-w-[550px] md:absolute md:bottom-20 md:right-[148px] md:gap-6 md:p-12 shadow-[0_4px_24px_rgba(0,0,0,0.16)]">
        {progress === 0 || progress! > 0 ? (
          <>
            <div className="flex ppp:flex-col ppp:gap-3 md:flex-row">
              <h3 className="font-bold ppp:text-xs pp:text-base md:text-3xl md:max-w-[190px] ppp:text-[16px]">
                Saldo Cashback
              </h3>
              {progress === 0 ? (
                <div className="flex ppp:gap-2 ppp:items-center">
                  <span>
                    <img
                      src="https://decathlonstore.vteximg.com.br/arquivos/finance_zero.png"
                      alt=""
                    />
                  </span>
                  <span className="pp:text-base md:text-2xl">R$ 0,00</span>
                </div>
              ) : (
                <div className="flex ppp:gap-2 ppp:items-center">
                  <span>
                    <img
                      src="https://decathlonstore.vteximg.com.br/arquivos/finance.png"
                      alt=""
                    />
                  </span>
                  <span className="pp:text-base md:text-2xl">
                    {progressBRL}
                  </span>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            {userCpf === null && isLoggedIn ? (
              <>
                <div className="flex ppp:flex-col ppp:gap-3 md:flex-row">
                  <h3 className="font-bold ppp:text-xs pp:text-base md:text-3xl md:max-w-[190px] ppp:text-[16px]">
                    Saldo Cashback
                  </h3>
                  <div className="flex ppp:gap-2 ppp:items-center">
                    <span>
                      <img
                        src="https://decathlonstore.vteximg.com.br/arquivos/finance.png"
                        alt=""
                      />
                    </span>
                    <span className="pp:text-base md:text-2xl">--</span>
                  </div>
                </div>
                <p className="ppp:text-[10px] pp:text-xs md:text-lg">
                  Cliente Clube? <br />
                  Preencha seu CPF
                  <Link to="/account" className="text-blue font-bold">
                    &nbsp;clicando aqui &nbsp;
                  </Link>
                  para consultar seu saldo.
                </p>
              </>
            ) : (
              <>
                <div className="flex ppp:flex-col ppp:gap-3 md:flex-row">
                  <h3 className="font-bold ppp:text-xs pp:text-base md:text-3xl md:max-w-[190px] ppp:text-[16px]">
                    Saldo Cashback
                  </h3>
                  <div className="flex ppp:gap-2 ppp:items-center">
                    <span>
                      <img
                        src="https://decathlonstore.vteximg.com.br/arquivos/finance.png"
                        alt=""
                      />
                    </span>
                    <span className="pp:text-base md:text-2xl">--</span>
                  </div>
                </div>
                <p className="pp:text-xs md:text-lg ppp:text-[12px]">
                  Cliente Clube?
                  <br />
                  <Link to="/account" className="text-blue font-bold">
                    Faça login
                  </Link>{' '}
                  para consultar seu saldo.
                </p>
              </>
            )}
          </>
        )}
      </div>
    </section>
  )
}

export default CashbackBanner
